import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Layout.vue'),
    beforeEnter: (to, from, next) => {
      // 路由进入前的操作
      let token = JSON.parse(localStorage.getItem('token') ? localStorage.getItem('token'):null)
      if (token && token.length == 12) {
        next()
      } else {
        next('/login')
      }
    },
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('../views/index.vue')
      },
      {
        path: 'timer',
        component: () => import('../views/components/timer.vue')
      }, {
        path: 'orders',
        component: () => import('../views/components/orders.vue')
      }, {
        path: 'user',
        component: () => import('../views/components/user.vue')
      }, {
        path: 'addprice',
        component: () => import('../views/components/addprice.vue')
      }, {
        path: 'setting',
        component: () => import('../views/setting.vue')
      }, {
        path: 'adminlist',
        component: () => import('../views/components/adminlist.vue')
      }, {
        path: 'eventlist',
        component: () => import('../views/components/eventlist.vue')
      }, {
        path: 'subslist',
        component: () => import('../views/components/subslist.vue')
      }, {
        path: 'activelist',
        component: () => import('../views/components/activelist.vue')
      },{
        path: 'calendar',
        component:() => import('../views/components/calendar.vue')
      }
    ]
  }, {
    path: '/indexView',
    name: 'select',
    component: () => import('../views/ScreenView/index.vue'),
  }, {
    path: '/pc_index',
    name: 'FrontIndex',
    component: () => import('../views/index/index.vue')
  }, {
    path: '/m_index',
    name: 'mobileIndex',
    component: () => import('../views/index/m_index.vue')
  }, {
    path: '/newslist',
    name: 'newsList',
    component: () => import('../views/index/components/newsList.vue')
  }, {
    path: '/m_newslist',
    name: 'mnewslist',
    component: () => import('../views/index/components/m_newsList.vue')
  }, {
    path: '/newsdetail/:id',
    name: 'newsDetail',
    component: () => import('../views/index/components/newsDetail.vue')
  }, {
    path: '/m_newsdetail/:id',
    name: 'mnewsDetail',
    component: () => import('../views/index/components/m_newsDetail.vue')
  }, {
    path: '/activeWork',
    name: 'activeWork',
    component: () => import('../views/index/components/activeWork.vue')
  }, {
    path: '/m_activeWork',
    name: 'mactiveWork',
    component: () => import('../views/index/components/m_activeWork.vue')
  }, {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/index/components/apply.vue')
  }, {
    path: '/m_apply',
    name: 'mapply',
    component: () => import('../views/index/components/m_apply.vue')
  }, {
    path: '/m_camera/:id',
    name: 'camera',
    component: () => import('../views/index/components/m_camera.vue')
  },{
    path:'/test',
    name:'test',
    component:() => import('../views/ScreenView/test.vue')
  },{
    path:'/Qzone',
    name:'QQzone',
    component:() => import('../views/index/components/Qzone.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
